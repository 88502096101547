import {setupIntersectionObserver} from "./event/utils";

document.body.classList.add('js-enabled');

setupIntersectionObserver('underline', 'underline-js', 0.75);

// Get all the images with class 'single__products-image'
const images = document.querySelectorAll(".single__products-image");

if (images.length > 0) {
    // Get the modal element
    const modal = document.getElementById("image-modal");
    const header = document.querySelector('.headers');
    const zIndexInitial = header.style.zIndex;

    // Get the modal content element
    const modalContent = document.querySelector(".container-modal");

    // Get the close button element
    const closeButton = document.querySelector(".close");

    // Get the previous and next buttons
    const prevButton = document.querySelector(".prev");
    const nextButton = document.querySelector(".next");

    let currentIndex;

    // Function to open the modal
    function openModal(index) {
        modal.style.display = "block";
        currentIndex = index;
        header.style.zIndex = "0";

        // Clear the modal content
        modalContent.innerHTML = "";

        // Create a new image element
        const enlargedImage = document.createElement("img");
        enlargedImage.src = images[currentIndex].src;

        // Add the enlarged image to the modal content
        modalContent.appendChild(enlargedImage);
    }

    // Function to close the modal
    function closeModal() {
        modal.style.display = "none";
        header.style.zIndex = zIndexInitial;
    }

    // Function to show previous image
    function showPrevImage() {
        if (currentIndex === 0) {
            currentIndex = images.length - 1;
        } else {
            currentIndex--;
        }

        const enlargedImage = document.createElement("img");
        enlargedImage.src = images[currentIndex].src;
        modalContent.innerHTML = "";
        modalContent.appendChild(enlargedImage);
    }

    // Function to show next image
    function showNextImage() {
        if (currentIndex === images.length - 1) {
            currentIndex = 0;
        } else {
            currentIndex++;
        }

        const enlargedImage = document.createElement("img");
        enlargedImage.src = images[currentIndex].src;
        modalContent.innerHTML = "";
        modalContent.appendChild(enlargedImage);
    }

    // Add click event listeners to each image
    images.forEach((image, index) => {
        image.addEventListener("click", () => {
            openModal(index);
        });
    });

    // Add click event listener to close button
    closeButton.addEventListener("click", closeModal);

    // Add click event listener to previous button
    prevButton.addEventListener("click", showPrevImage);

    // Add click event listener to next button
    nextButton.addEventListener("click", showNextImage);

    // Add event listener to close modal on outside click
    window.addEventListener("click", (event) => {
        if (event.target === modal) {
            closeModal();
        }
    });
}

const evitementLink = document.querySelector('.evitement');
const contenuMain = document.getElementById('contenu');

evitementLink.addEventListener('click', function (event) {
    event.preventDefault(); // Empêche le comportement de lien par défaut
    contenuMain.focus(); // Déplace le focus vers le contenu principal
});