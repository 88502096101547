export function setupIntersectionObserver(className, addClass, threshold, disconnect) {
    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            // Log if the element and if it's in the viewport
            if (entry.isIntersecting) {
                entry.target.classList.add(addClass);
            } /*else {
                // Retirer la classe spécifiée
                entry.target.classList.remove(addClass);
            }*/
        });
    }, { threshold: threshold });

    const elements = document.getElementsByClassName(className);
    for (const element of elements) {
        observer.observe(element);
    }
    if (disconnect === true) {
        observer.disconnect();
    }
}
